import {Vector3, Object3D, Quaternion, ArrayCamera} from "three";
import {DirectionToSpherical} from "../Helpers/utils.js";
import { Api } from "../Api.js";
import {Web3DCamera} from "./Web3DCamera.js";
import {Vector3Const} from "../Helpers/common-utils.js";

export class XRManager {
    private session: any;
    private origOrderIndependentTransparency: boolean;
    private mode: XRSessionMode;
    cameraRig: Object3D;

    constructor(private api: Api) {}

    static async isVRAvailable(): Promise<boolean> {
        return XRManager.isXRAvailable("immersive-vr");
    }

    static async isARAvailable(): Promise<boolean> {
        return XRManager.isXRAvailable("immersive-ar");
    }

    private static async isXRAvailable(mode: string): Promise<boolean> {
        if (!("xr" in navigator)) return false;
        try {
            // @ts-ignore
            return navigator.xr && await navigator.xr.isSessionSupported(mode);
        } catch {
            // Access to navigator.xr disallowed by browser permissions
            return false;
        }
    }

    private async startXR(mode: XRSessionMode, options: XRSessionInit, refSpaceType: XRReferenceSpaceType): Promise<void> {
        this.mode = mode;
        this.api.renderingManager.renderer.xr.enabled = true;
        this.api.renderingManager.renderer.xr.setReferenceSpaceType(refSpaceType);
        this.api.renderingManager.uniforms.xrEnabled = true;
        // @ts-ignore
        const xr = navigator.xr;
        const session = await xr.requestSession(mode, options);
        this.session = session;
        session.onend = this.onEnd;

        await this.api.renderingManager.renderer.xr.setSession(session);

        this.api.renderingManager.addAnimationFrameListener(this._update);

        // depth peeling is not supported in VR
        this.origOrderIndependentTransparency = this.api.settingsDispatcher.settings.orderIndependentTransparency;

        // Reset camera polar rotation, so vr avatar is standing straight up
        const sphericalDirection = DirectionToSpherical(this.api.camera.getWorldDirection(new Vector3()), this.api.camera.up);
        sphericalDirection.x = Math.PI / 2;
        this.api.camera.rotateSpherical(this.api.camera.position, Vector3Const.zero, sphericalDirection);

        this.api.camera.near = 0.01;
        this.api.camera.far = 10000;
        this.api.camera.updateProjectionMatrix();

        this._createCameraRig();
    }

    async startVR(): Promise<void> {
        await this.startXR("immersive-vr", {optionalFeatures: ["local-floor", "bounded-floor"]}, "local-floor");
    }

    async startAR(): Promise<void> {
        await this.startXR("immersive-ar", undefined, "local");
    }

    end(): void {
        this.session.end();
    }

    get isStarted(): boolean {
        return !!(this.session && this.api.renderingManager.renderer.xr.isPresenting);
    }

    get isStartedVR(): boolean {
        return this.isStarted && this.mode === "immersive-vr";
    }

    get isStartedAR(): boolean {
        return this.isStarted && this.mode === "immersive-ar";
    }

    private onEnd = () => {
        if (!this.session) return;

        this.api.renderingManager.removeAnimationFrameListener(this._update);
        this.api.renderingManager.uniforms.xrEnabled = false;
        this.api.settingsDispatcher.settings.orderIndependentTransparency = this.origOrderIndependentTransparency;
        this.session = undefined;
        this._removeCameraRig();
    }

    private _createCameraRig(): void {
        this.cameraRig = new Object3D();
        this.cameraRig.position.copy(this.api.camera.position);
        this.cameraRig.quaternion.copy(this.api.camera.quaternion);
        this.api.camera.position.set(0, 0, 0);
        this.api.camera.quaternion.copy(new Quaternion());

        // Dont ask... https://github.com/mrdoob/three.js/issues/737
        const rig2 = new Object3D();
        rig2.up = Vector3Const.up;
        rig2.add(this.api.camera);

        this.cameraRig.add(rig2);
    }

    private _removeCameraRig(): void {
        this.api.camera.position.copy(this.cameraRig.position);
        this.api.camera.quaternion.copy(this.cameraRig.quaternion);
        this.api.camera.parent.remove(this.api.camera);
        this.cameraRig = undefined;
    }

    private _update = (() => {
        return () => {
            this.api.camera.updateWorldMatrix(true, false);
            this.api.camera.callListeners();
        };
    })();

    // Returns VR camera if vr is enabled, original camera otherwise
    toVRCamera(camera: Web3DCamera): Web3DCamera | ArrayCamera {
        return camera.isPerspectiveCamera && this.isStarted ? this.api.renderingManager.renderer.xr.getCamera() as ArrayCamera : camera;
    }

    get textureWidth(): number {
        return this.session.renderState.layers[0].textureWidth;
    }

    get textureHeight(): number {
        return this.session.renderState.layers[0].textureHeight;
    }
}
