import {ClipPlaneEventName, MouseButton, Settings, SnappingTool, SnapType, Web3DEventName} from "../common.js";
import {Box3, Line3, Matrix4, Plane, Quaternion, Ray, Vector3} from "three";
import {addIntersection, InputHandler, PointerInput, PointerObservableOptions} from "../InputHandler.js";
import {Api} from "../Api.js";
import {ClipBox} from "../ClipBox.js";
import {mergeMap} from "rxjs/operators";
import {IIntersection} from "../Picker/IIntersection.js";
import {Tool} from "./Tool.js";
import {ClipPlaneModel} from "../ClipPlaneModel.js";
import {Cursor3D} from "../Picker/Cursor3D.js";
import {Observable, Subscription} from "rxjs";
import {screenPositionToRay} from "../Picker/Picker.js";
import {calculateIntersectionOfLines} from "../Helpers/utils.js";
import {Key} from "ts-key-enum";

interface DragConfiguration {
    snapType: SnapType;
    faces: number[]; // faces of the clip box
    guideLines: Line3[]; // guide lines for dragging
    chosenGuideLine: number; // index of the guide line that is being dragged
    dragPlane: Plane; // plane for dragging corners
}

export class ClipBoxTool  extends Tool implements SnappingTool {
    static get Name(): string { return "clipBox"; }
    private clipBox: ClipBox;
    private readonly _clipBoxPlanes: Plane[] = [];
    private _limitingPoints: Vector3[] = [];
    private dragConfiguration: DragConfiguration = {
        snapType: undefined,
        faces: [],
        guideLines: [new Line3(), new Line3()], // guide lines for dragging faces along box axes
        chosenGuideLine: -1, // index of the guide line that is being dragged
        dragPlane: new Plane() // plane for dragging corners
    };
    private _moveSubscription: Subscription;
    private _tapsHandle: Subscription;
    private _tapsObservableOptions: PointerObservableOptions;
    private _dragObservable: Observable<PointerInput>;
    private _dragSubscription: Subscription;
    private model: ClipPlaneModel;
    private ray: Ray = new Ray();
    private intersectionPoint: Vector3 = new Vector3();
    private _cursor: Cursor3D;
    private _snapTypes: SnapType[] = [SnapType.FACE];
    private _controlsVisible: boolean = true;
    private _blockHoverSelect = false; // prevents hover selection when another tool is dragging
    private _dragging = false; // indicates if the tool is currently dragging
    private dragObjectSelected = false; // indicates if the tool is allowed to drag
    private _clipBoxModified = false;

    get name(): string {
        return ClipBoxTool.Name;
    }

    get allowedSnapTypes(): SnapType[] {
        return [SnapType.FACE];
    }

    set snapTypes(value: SnapType[]) {
        this._snapTypes = value;
        if (this.enabled) this._cursor.snapTypes = value;
    }

    get snapTypes(): SnapType[] {
        return this._snapTypes;
    }

    set controlsVisible(visible: boolean) {
        this.clipBox.edgesVisible = visible;
        this._controlsVisible = visible;
        this._api.renderingManager.redraw();
    }

    get controlsVisible(): boolean {
        return this._controlsVisible;
    }

    getClipBoxId(): number {
        return this.clipBox.id;
    }

    override get enabled(): boolean {
        return !!this._tapsHandle;
    }

    override set enabled(enabled: boolean) {
        if (this._tapsHandle) {
            this._tapsHandle.unsubscribe();
            this._tapsHandle = null;
            this._cursor.unsubscribe();
        }
        if (!enabled) return;

        // deselect clip box when tool is enabled
        this._api.selection.subtract(this.getModel().modelId, [this.getClipBoxId()]);

        this._cursor = this._api.cursor;
        this._cursor.snapTypes = this.snapTypes;
        this._cursor.subscribe();

        const taps = this._inputs.createSnappedTapObservable(this._cursor, this._tapsObservableOptions);
        this._tapsHandle = taps.subscribe(event => {
            this.addClipBox(event);
        });
    }

    constructor(
        private _inputs: InputHandler,
        private _api: Api,
        private settings: Settings
    ) {
        super();

        // clipping planes
        this.createClipBoxPLanes();

        // visual box
        this.clipBox = new ClipBox(this._api);

        this.createLimitingPoints();

        this.snapTypes = this.allowedSnapTypes;

        this.settings.clipPlaneCuts = false;
        this.settings.intersectingClipPlanes = false;

        this._tapsObservableOptions = {button: MouseButton.left, touchCount: 1};

        this._dragObservable = _api.inputHandler.createDragObservable({button: MouseButton.left, touchCount: 3},
            e => this.downCallback(e),
            e => this.moveCallback(e),
            e => this.upCallback(e));

        // if another tool starts dragging prevent hover selection.
        this._api.eventDispatcher.subscribe(Web3DEventName.DragStart, e => {if (!(e.detail instanceof ClipBoxTool)) this._blockHoverSelect = true;});

        // enable hover selection when another tool stops dragging.
        this._api.eventDispatcher.subscribe(Web3DEventName.DragEnd, e => {if (!(e.detail instanceof ClipBoxTool)) this._blockHoverSelect = false;});

        this.startKeyListener();
    }

    add(position: Vector3, quaternion: Quaternion, box: Box3, origin?: any): ClipBox {
        this.clipBox.update(position, quaternion, box);
        this.updateClipBoxPlanes();
        this.addClipBoxPlanes();

        if (!this.clipBox.parent)
            this.getModel().root.add(this.clipBox);

        this.clipBox.origin = origin;

        this._api.renderingManager.renderer.localClippingEnabled = true;
        this._api.camera.callListeners();

        this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.BoxAdded, { detail: this.clipBox }));

        return this.clipBox;
    }

    clear(origin?: any): void {
        this._api.selection.subtract(this.getModel().modelId, [this.getClipBoxId()]);
        this.removeClipBox(origin);
        this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.BoxRemoved, {detail: this.clipBox}));
    }

    selectClipBox(): void {
        if (this.clipBox.selected)
            return;

        if (this._api.toolManager.activeTool === this.name)
            this._api.toolManager.activateDefaultTool();

        this._api.selection.add(this.getModel().modelId, [this.getClipBoxId()]);
    }

    deselectClipBox(): void {
        this._api.selection.subtract(this.getModel().modelId, [this.getClipBoxId()]);
    }

    startEditing(): void {
        this.startMoveSubscription();
        this._dragSubscription = this._dragObservable.subscribe();
    }

    stopEditing(): void {
        this.stopMoveSubscription();
        if (this._dragSubscription) {
            this._dragSubscription.unsubscribe();
            this._dragSubscription = null;
        }
    }

    private getModel(): ClipPlaneModel {
        if (this.model) return this.model;

        this.model = this._api.models.get(ClipPlaneModel.ModelId) as ClipPlaneModel;
        if (this.model) return this.model;

        this.model = new ClipPlaneModel(this._api);
        this._api.models.add(this.model);
        return this.model;
    }

    private getWorldBoundingBox(): Box3 {
        const boundingBox = new Box3();
        const models = this._api.models.getModels();
        for (const m of models) {
            if (m !== this.model)
                boundingBox.union(m.getModelBoundingBox());
        }
        return boundingBox;
    }

    private createClipBoxPLanes(): void {
        const xAxis = new Vector3(1,0,0);
        const yAxis = new Vector3(0,1,0);
        const zAxis = new Vector3(0,0,1);

        this._clipBoxPlanes.push(new Plane(xAxis.clone().negate(), 0));
        this._clipBoxPlanes.push(new Plane(xAxis, 0));
        this._clipBoxPlanes.push(new Plane(yAxis.clone().negate(), 0));
        this._clipBoxPlanes.push(new Plane(yAxis, 0));
        this._clipBoxPlanes.push(new Plane(zAxis.clone().negate(), 0));
        this._clipBoxPlanes.push(new Plane(zAxis, 0));
    }

    private createLimitingPoints(): void {
        for (let i = 0; i < 8; i++) {
            this._limitingPoints.push(new Vector3());
        }
    }

    private addClipBox(event: PointerInput): PointerInput {
        let position: Vector3;
        let normal: Vector3;

        if (this._cursor.intersection.snapLineStart) {
            position = this._cursor.intersection.point.clone();
            normal = this._cursor.intersection.snapLineEnd.clone().sub(this._cursor.intersection.snapLineStart).normalize();
            if (normal.dot(this._api.camera.position.clone().sub(position)) > 0) normal.negate();

            // align box to line normal only if line is horizontal
            if (Math.abs(normal.z) > 0.01)
                normal.set(1,0,0);
        }
        else if (this._cursor.intersection.normal) {
            position = this._cursor.intersection.point.clone().add(this._cursor.intersection.normal.clone().multiplyScalar(0.01));
            normal = this._cursor.intersection.normal.clone().negate();

            // align box to face normal only if face normal is horizontal
            if (Math.abs(normal.z) > 0.01)
                normal.set(1,0,0);
        } else {
            position = this._cursor.intersection.point.clone();
            normal = new Vector3(1, 0, 0);
        }

        const maxSize = this.clipBoxMaxSizeToFitWorldBoundingBox(position);
        const sizeFromCamera = this.clipBoxSizeFromCameraPosition(position);
        const size = Math.min(maxSize, sizeFromCamera);
        const box = new Box3(new Vector3(-size,-size,-size), new Vector3(size,size,size));

        const up = new Vector3(0,0,1);
        const xAxis = normal.clone();
        const yAxis = up.clone().cross(normal).normalize();
        const zAxis = normal.clone().cross(yAxis).normalize();

        const quaternion = new Quaternion().setFromRotationMatrix(new Matrix4().makeBasis(xAxis, yAxis, zAxis));

        this.add(position, quaternion, box, this);

        return event;
    }

    private clipBoxSizeFromCameraPosition(clipBoxPosition: Vector3): number {
        return 0.2 * this._api.camera.position.distanceTo(clipBoxPosition);
    }

    private clipBoxMaxSizeToFitWorldBoundingBox(position: Vector3): number {
        const wbb = this._api.models.worldBoundingBox.value;
        const x = 2 * Math.min( Math.abs(position.x - wbb.min.x) ,  Math.abs(position.x - wbb.max.x) );
        const y = 2 * Math.min( Math.abs(position.y - wbb.min.y) ,  Math.abs(position.y - wbb.max.y) );
        const z = 2 * Math.min( Math.abs(position.z - wbb.min.z) ,  Math.abs(position.z - wbb.max.z) );
        return Math.min(x, y, z) / Math.sqrt(3);
    }

    private removeClipBox(origin?: any): void {
        this.clipBox.origin = origin;

        if (this.clipBox.parent)
            this.getModel().root.remove(this.clipBox);

        this.removeClipBoxPlanes();

        this._api.camera.callListeners();
        this._api.renderingManager.redraw(true);
    }

    private updateClipBoxPlanes(): void {
        const xAxis = new Vector3(1,0,0);
        const yAxis = new Vector3(0,1,0);
        const zAxis = new Vector3(0,0,1);

        const worldPos = this.clipBox.position;
        const box = this.clipBox.getBox();
        this.clipBox.matrixWorld.extractBasis(xAxis, yAxis, zAxis);

        // +X plane
        this._clipBoxPlanes[1].setFromNormalAndCoplanarPoint(xAxis, worldPos.clone().add(xAxis.clone().multiplyScalar(box.min.x)));
        // -X plane
        this._clipBoxPlanes[0].setFromNormalAndCoplanarPoint(xAxis.clone().negate(), worldPos.clone().add(xAxis.clone().multiplyScalar(box.max.x)));
        // +Y plane
        this._clipBoxPlanes[3].setFromNormalAndCoplanarPoint(yAxis, worldPos.clone().add(yAxis.clone().multiplyScalar(box.min.y)));
        // -Y plane
        this._clipBoxPlanes[2].setFromNormalAndCoplanarPoint(yAxis.clone().negate(), worldPos.clone().add(yAxis.clone().multiplyScalar(box.max.y)));
        // +Z plane
        this._clipBoxPlanes[5].setFromNormalAndCoplanarPoint(zAxis, worldPos.clone().add(zAxis.clone().multiplyScalar(box.min.z)));
        // -Z plane
        this._clipBoxPlanes[4].setFromNormalAndCoplanarPoint(zAxis.clone().negate(), worldPos.clone().add(zAxis.clone().multiplyScalar(box.max.z)));
    }

    private addClipBoxPlanes(): void {
        this.removeClipBoxPlanes();
        for (const cbp of this._clipBoxPlanes)
            this._api.renderingManager.clippingPlanes.push(cbp);
    }

    private removeClipBoxPlanes(): void {
        for (let i = this._api.renderingManager.clippingPlanes.length - 1; i > -1 ; i--) {
            if (this._clipBoxPlanes.includes(this._api.renderingManager.clippingPlanes[i]))
                this._api.renderingManager.clippingPlanes.splice(i, 1);
        }
        if (this._api.renderingManager.clippingPlanes.length === 0) {
            this._api.renderingManager.renderer.localClippingEnabled = false;
        }
    }

    private startMoveSubscription(): void {
        if (this._moveSubscription)
            return;
        this._moveSubscription = this._api.inputHandler.pointerMove$.pipe(mergeMap(addIntersection(this._api.inputHandler.picker, [this.model]))).subscribe(e => this.onHover(e));
    }

    private stopMoveSubscription(): void {
        if (this._moveSubscription) {
            this._moveSubscription.unsubscribe();
            this._moveSubscription = null;
        }
    }

    private onHover(event: PointerInput): void {
        if (this._blockHoverSelect)
            return;
        if (event.intersection && event.intersection.object && event.intersection.object instanceof ClipBox) {
            this.setDragConfiguration(event.intersection);
            this.dragObjectSelected = true;
        } else {
            this.clearDragConfiguration();
            this.dragObjectSelected = false;
        }

        this._api.renderingManager.redraw();
    }

    private setDragConfiguration(intersection: IIntersection): void {
        this.highlightBoxByIndex(intersection.index);
        if (intersection.index < 6) {
            this.dragConfiguration.snapType = SnapType.FACE;
            this.dragConfiguration.faces[0] = intersection.index;
            this.dragConfiguration.guideLines[0].set(intersection.point, intersection.point.clone().add(this.copyBoxPlaneNormal(intersection.index)));
            return;
        }

        if (intersection.index < 18) {
            this.dragConfiguration.snapType = SnapType.LINE;
            [this.dragConfiguration.faces[0], this.dragConfiguration.faces[1]] = this.clipBox.getFaceIndicesFromLine(intersection.index);
            this.dragConfiguration.guideLines[0].set(intersection.point, intersection.point.clone().add(this.copyBoxPlaneNormal(this.dragConfiguration.faces[0])));
            this.dragConfiguration.guideLines[1].set(intersection.point, intersection.point.clone().add(this.copyBoxPlaneNormal(this.dragConfiguration.faces[1])));
            return;
        }

        if (intersection.index < 26) {
            this.dragConfiguration.snapType = SnapType.POINT;
            const faceIndices = this.clipBox.getCornerToFaces((intersection.index));
            let dragPlaneIndex = -1;
            for (let i = 0; i < faceIndices.length; i++) {
                const normalZ = this.copyBoxPlaneNormal(faceIndices[i]).z;
                if (Math.abs(normalZ) > 0.999) {
                    dragPlaneIndex = i;
                    this.dragConfiguration.dragPlane.set(this.copyBoxPlaneNormal(faceIndices[i]), this.copyBoxPlaneConstant(faceIndices[i]));
                }
            }

            if (dragPlaneIndex === -1) {
                // find alternative plane.
            } else {
                let count = 0;
                for (let i = 0; i < faceIndices.length; i++) {
                    if (i !== dragPlaneIndex) {
                        this.dragConfiguration.faces[count] = faceIndices[i];
                        count++;
                    }
                }
            }
            return;
        }

    }

    private clearDragConfiguration(): void {
        this.dragConfiguration.snapType = undefined;
        this.highlightBoxByIndex(-1);
    }

    private copyBoxPlaneNormal(index: number): Vector3 {
        return this._clipBoxPlanes[index].normal.clone();
    }

    private copyBoxPlaneConstant(index: number): number {
        return this._clipBoxPlanes[index].constant;
    }

    private highlightBoxByIndex(index: number): void {
        this.clipBox.highlightByIndex(index);
    }

    private downCallback(event: PointerInput): PointerInput {
        if (this.dragObjectSelected === false)
            return event;

        if (this.dragConfiguration.snapType !== undefined) {
            this._api.eventDispatcher.dragStart(this);
            this._dragging = true;
            this.updateLimitingBoundingBox(); // sets limits for how far box can be dragged based on the world bounding box of all models
            this.stopMoveSubscription();
        }
        return event;
    }

    private async moveCallback(event: PointerInput): Promise<void> {
        if (!this._dragging)
            return;

        if (this.dragConfiguration.snapType === SnapType.FACE) {
            this.faceDrag(event);
            this._clipBoxModified = true;
        } else if (this.dragConfiguration.snapType === SnapType.LINE) {
            this.edgeDrag(event);
            this._clipBoxModified = true;
        } else if (this.dragConfiguration.snapType === SnapType.POINT) {
            this.cornerDrag(event);
            this._clipBoxModified = true;
        }
    }

    private upCallback(event: PointerInput): PointerInput {
        this.startMoveSubscription();
        this._dragging = false;
        this.dragConfiguration.chosenGuideLine = -1;

        this._api.eventDispatcher.dragEnd(this);
        if (this._clipBoxModified) {
            this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.BoxModified, { detail: this.clipBox }));
            this._clipBoxModified = false;
        }

        return event;
    }

    private updateLimitingBoundingBox(): void {
        this.updateLimitingPointsFromBoundingBox(this.getWorldBoundingBox());
    }

    private updateLimitingPointsFromBoundingBox(box: Box3): void {
        const e = 0.2 * (Math.min(box.max.x - box.min.x, box.max.y - box.min.y, box.max.z - box.min.z));
        this._limitingPoints[0].set(box.min.x - e, box.min.y - e, box.min.z - e);
        this._limitingPoints[1].set(box.max.x + e, box.min.y - e, box.min.z - e);
        this._limitingPoints[2].set(box.min.x - e, box.max.y + e, box.min.z - e);
        this._limitingPoints[3].set(box.min.x - e, box.min.y - e, box.max.z + e);
        this._limitingPoints[4].set(box.max.x + e, box.max.y + e, box.min.z - e);
        this._limitingPoints[5].set(box.min.x - e, box.max.y + e, box.max.z + e);
        this._limitingPoints[6].set(box.max.x + e, box.min.y - e, box.max.z + e);
        this._limitingPoints[7].set(box.max.x + e, box.max.y + e, box.max.z + e);
    }

    private faceDrag(event: PointerInput): void {
        screenPositionToRay({ x: event.x, y: event.y }, this._api.container, this._api.camera, this.ray);
        this.intersectionPoint.copy(calculateIntersectionOfLines(this.ray.origin, this.ray.origin.clone().add(this.ray.clone().direction), this.dragConfiguration.guideLines[0].start, this.dragConfiguration.guideLines[0].end)[0]);
        this.moveFacesToWorldPosition([this.dragConfiguration.faces[0]], [this.intersectionPoint]);
    }

    private edgeDrag(event: PointerInput): void {
        screenPositionToRay({ x: event.x, y: event.y }, this._api.container, this._api.camera, this.ray);

        // when movement starts pick the closest line to the pick ray to move along then continue moving along the same line
        if (this.dragConfiguration.chosenGuideLine === -1) {
            const [point1OnPickRay, pointOnGuideLine1] = calculateIntersectionOfLines(this.ray.origin, this.ray.origin.clone().add(this.ray.clone().direction), this.dragConfiguration.guideLines[0].start, this.dragConfiguration.guideLines[0].end);
            const [point2OnPickRay, pointOnGuideLine2] = calculateIntersectionOfLines(this.ray.origin, this.ray.origin.clone().add(this.ray.clone().direction), this.dragConfiguration.guideLines[1].start, this.dragConfiguration.guideLines[1].end);

            const distanceSq1 = point1OnPickRay.distanceToSquared(pointOnGuideLine1);
            const distanceSq2 = point2OnPickRay.distanceToSquared(pointOnGuideLine2);

            if (distanceSq1 < distanceSq2) {
                this.dragConfiguration.chosenGuideLine = 0;
                this.moveFacesToWorldPosition([this.dragConfiguration.faces[0]], [point1OnPickRay]);
                this.highlightBoxByIndex(this.dragConfiguration.faces[0]);
            } else {
                this.dragConfiguration.chosenGuideLine = 1;
                this.moveFacesToWorldPosition([this.dragConfiguration.faces[1]], [point2OnPickRay]);
                this.highlightBoxByIndex(this.dragConfiguration.faces[1]);
            }

        } else {
            this.intersectionPoint = calculateIntersectionOfLines(this.ray.origin, this.ray.origin.clone().add(this.ray.clone().direction), this.dragConfiguration.guideLines[this.dragConfiguration.chosenGuideLine].start, this.dragConfiguration.guideLines[this.dragConfiguration.chosenGuideLine].end)[1];
            this.moveFacesToWorldPosition([this.dragConfiguration.faces[this.dragConfiguration.chosenGuideLine]], [this.intersectionPoint]);
        }
    }

    private cornerDrag(event: PointerInput): void {
        screenPositionToRay({ x: event.x, y: event.y }, this._api.container, this._api.camera, this.ray);
        this.ray.intersectPlane(this.dragConfiguration.dragPlane, this.intersectionPoint);
        this.moveFacesToWorldPosition([this.dragConfiguration.faces[0], this.dragConfiguration.faces[1]], [ this.intersectionPoint, this.intersectionPoint]);
    }

    private moveFacesToWorldPosition(faceIndices: number[], worldPositions: Vector3[]): void {
        if (!(faceIndices && worldPositions && faceIndices.length === worldPositions.length)) {
            return;
        }

        for (let i = 0; i < faceIndices.length; i++) {
            if (this.isPointPositionValid(faceIndices[i], worldPositions[i])) {
                const index = faceIndices[i];
                const plane = this._clipBoxPlanes[index];
                plane.setFromNormalAndCoplanarPoint(plane.normal.clone(), worldPositions[i].clone());
            }
        }

        const [box, position] = this.calculateBoxParametersFromPlanes();
        this.clipBox.updateFromCenterAndBox(position, box);
        this.getModel().updateBoundingBox();
        this._api.camera.callListeners();
        this._api.renderingManager.redraw();
    }

    private isPointPositionValid(faceIndex: number, point: Vector3): boolean {
        return this.isPointOnPlaneWithinLimits(faceIndex, point) && this.isPointOnPlaneFarEnoughFromOppositePlane(faceIndex, point);
    }

    private isPointOnPlaneWithinLimits(faceIndex: number, point: Vector3): boolean {
        const plane = new Plane(); // TODO: refactor to reuse one plane
        plane.setFromNormalAndCoplanarPoint(this._clipBoxPlanes[faceIndex].normal.clone(), point);

        let allOnSameSide = true;
        const side = plane.distanceToPoint(this._limitingPoints[0]);
        let shortestDistance = side;
        let closestCorner = this._limitingPoints[0];
        for (let j = 1; j < this._limitingPoints.length; j++) {
            const distance = plane.distanceToPoint(this._limitingPoints[j]);
            if (distance * side < 0) {
                allOnSameSide = false;
                break;
            }
            if (Math.abs(distance) < Math.abs(shortestDistance)) {
                shortestDistance = distance;
                closestCorner = this._limitingPoints[j];
            }
        }

        return !allOnSameSide;
    }

    private isPointOnPlaneFarEnoughFromOppositePlane(faceIndex: number, point: Vector3): boolean {
        const e = 0.1; // minimum distance between planes
        if (faceIndex % 2 === 0) {
            const oppositeFaceIndex = faceIndex + 1;
            return this._clipBoxPlanes[oppositeFaceIndex].distanceToPoint(point) > e;
        } else {
            const oppositeFaceIndex = (faceIndex - 1) % 6;
            return this._clipBoxPlanes[oppositeFaceIndex].distanceToPoint(point) > e;
        }
    }

    private calculateBoxParametersFromPlanes(): [Box3, Vector3] {
        const minX = this._clipBoxPlanes[1].constant * -this._clipBoxPlanes[0].normal.clone().length();
        const minY = this._clipBoxPlanes[3].constant * -this._clipBoxPlanes[2].normal.clone().length();
        const minZ = this._clipBoxPlanes[5].constant * -this._clipBoxPlanes[4].normal.clone().length();

        const maxX = this._clipBoxPlanes[0].constant * this._clipBoxPlanes[1].normal.clone().length();
        const maxY = this._clipBoxPlanes[2].constant * this._clipBoxPlanes[3].normal.clone().length();
        const maxZ = this._clipBoxPlanes[4].constant * this._clipBoxPlanes[5].normal.clone().length();

        const centre = new Vector3((maxX + minX) / 2, (maxY + minY) / 2, (maxZ + minZ) / 2);
        centre.applyQuaternion(this.clipBox.quaternion);

        return [
            new Box3(
                new Vector3(-0.5 * (maxX -minX), -0.5 * (maxY - minY), -0.5 * (maxZ - minZ)),
                new Vector3(0.5 * (maxX -minX), 0.5 * (maxY - minY), 0.5 * (maxZ - minZ))
            ),
            centre
        ];
    }

    private startKeyListener(): void {
        this._api.inputHandler.keyDown$.subscribe((e: KeyboardEvent) => {
            if (e.code === Key.Delete || e.code === Key.Backspace)
                this.removeIfSelected();
        });
    }

    private removeIfSelected(): void {
        if (this.clipBox.selected) {
            this._api.selection.subtract(this.model.modelId, [this.getClipBoxId()]);
            this.removeClipBox(this);
        }
        this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.BoxRemoved, {detail: this.clipBox}));
    }
}