import {Color, ShaderLib, ShaderMaterialParameters, Texture, UniformsUtils, Side, DoubleSide, ShaderChunk} from "three";
import {GlobalMaterialUniforms, RenderingManager} from "./RenderingManager.js";
import { Web3DMaterial } from "./Web3DMaterial.js";
import {Settings} from "../common.js";
import {SettingsDispatcher} from "../SettingsDispatcher.js";


// replacing threejs version to calculate planeDistance
ShaderChunk.clipping_planes_fragment = `
#if NUM_CLIPPING_PLANES > 0
    float planeDistance = -1.0;
	vec4 plane;
	float dist;
	#pragma unroll_loop_start
	for (int i = 0; i < UNION_CLIPPING_PLANES; i ++ ) {
		plane = clippingPlanes[ i ];
		dist = plane.w - dot( vClipPosition, plane.xyz );
		planeDistance = planeDistance == -1.0 ? dist : min(planeDistance, dist); 
		if ( dist < 0.0 ) discard;
	}
	#pragma unroll_loop_end

	#if UNION_CLIPPING_PLANES < NUM_CLIPPING_PLANES
		bool clipped = true;
		#pragma unroll_loop_start
		for ( int i = UNION_CLIPPING_PLANES; i < NUM_CLIPPING_PLANES; i ++ ) {
			plane = clippingPlanes[ i ];
			dist = plane.w - dot( vClipPosition, plane.xyz );
			planeDistance = planeDistance == -1.0 ? dist : min(planeDistance, dist); 
			clipped = ( dist < 0.0 ) && clipped;
		}
		#pragma unroll_loop_end
		if ( clipped ) discard;
	#endif
#endif
`;

// language=GLSL
const meshFragmentShader = `
    #include <common>
    #include <dithering_pars_fragment>
    #include <color_pars_fragment>
    #include <uv_pars_fragment>
    #include <map_pars_fragment>
    #include <clipping_planes_pars_fragment>
    #include <depth_peeling_pars_fragment>
    
    uniform vec3 diffuse;
    uniform float opacity;
    varying vec3 vNormal;
    flat varying vec3 flatNormal;
    
    vec3 frontFaceNormal(vec3 normal) {
       return normalize(gl_FrontFacing ? normal : -normal);
    } 
    
    #ifdef USE_ENVMAP
        uniform float metalness;
        uniform float roughness;
        varying vec3 vPosition;

        #include <bsdfs>
        #include <cube_uv_reflection_fragment>
        #include <envmap_common_pars_fragment>
        #include <lights_physical_pars_fragment>     
        
        void calculateColor(inout vec4 diffuseColor) {
            vec3 normal = frontFaceNormal(vNormal);
            vec3 viewDir = isOrthographic ? vec3(0, 0, 1) : normalize(-vPosition);

            vec3 dxy = max( abs( dFdx( normal ) ), abs( dFdy( normal ) ) );
            float geometryRoughness = max( max( dxy.x, dxy.y ), dxy.z );

            float specularRoughness = max(roughness, 0.0525);// 0.0525 corresponds to the base mip of a 256 cubemap.
            specularRoughness += geometryRoughness;
            specularRoughness = min(specularRoughness, 1.0);

            vec3 specularColor = mix(vec3(0.04), diffuseColor.rgb, metalness);

            vec3 worldNormal = inverseTransformDirection(normal, viewMatrix);
            vec3 irradiance =  PI * textureCubeUV(envMap, vec3(worldNormal.x, worldNormal.z, -worldNormal.y), 1.0).rgb;

            vec3 reflectVec = reflect(-viewDir, normal);
            reflectVec = normalize(mix(reflectVec, normal, specularRoughness * specularRoughness));
            reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
            vec3 radiance =  textureCubeUV(envMap, vec3(reflectVec.x, reflectVec.z, -reflectVec.y), roughness).rgb;

            vec3 singleScattering = vec3(0.0);
            vec3 multiScattering = vec3(0.0);
            vec3 cosineWeightedIrradiance = irradiance * RECIPROCAL_PI;
            
            computeMultiscattering(normal, viewDir, specularColor, 1.5, specularRoughness, singleScattering, multiScattering);
            vec3 diffuse = diffuseColor.rgb * (1.0 - metalness) * (1.0 - (singleScattering + multiScattering));

            vec3 indirectSpecular = radiance * singleScattering;
            indirectSpecular += multiScattering * cosineWeightedIrradiance;

            vec3 indirectDiffuse = diffuse * cosineWeightedIrradiance;

            diffuseColor.rgb = indirectDiffuse + indirectSpecular;
        }
    #elif defined(VERTEX_INTERPOLATION)
        varying float vAttenuation;
        
        void calculateColor(inout vec4 diffuseColor) {
            diffuseColor.rgb = diffuseColor.rgb * vAttenuation;
        }
    #else
        uniform float ambient;
        uniform float intensity;
        uniform float specular;
        uniform float specularHardness;
        varying vec3 vPosition;
        
        void calculateColor(inout vec4 diffuseColor) {
            // Clamp color to avoid loosing details in absolute black and white color values 
            diffuseColor.rgb = clamp(diffuseColor.rgb, vec3(0.1), vec3(0.9));

            vec3 viewVector = isOrthographic ? vec3(0, 0, 1) : normalize(-vPosition.xyz);
            float attenuation = abs(dot(viewVector, frontFaceNormal(vNormal)));
            diffuseColor.rgb = diffuseColor.rgb * (ambient + attenuation * intensity);
            diffuseColor.rgb += pow(1.0 - attenuation, specularHardness) * specular;
        }
    #endif

    #ifdef OUT_NORMAL_EDGE_BUFFER                
        layout(location = 1) out vec4 gNormal;
        layout(location = 2) out vec4 gFlagsAndCuts;
        varying float vId;
    #endif

    void main() {
        #include <clipping_planes_fragment>
        #include <depth_peeling_fragment>
        
        vec4 diffuseColor = vec4(diffuse, opacity);
        #ifdef USE_MAP
            diffuseColor *= texture2D(map, vUv);
        #endif
        #include <color_fragment>
        if (diffuseColor.a <= 0.0)
            discard; // better edge lines for transparent textures
        
        calculateColor(diffuseColor);
        gl_FragColor = diffuseColor;
        #include <tonemapping_fragment>
        #include <colorspace_fragment>
        #include <dithering_fragment>
        
        #ifdef OUT_NORMAL_EDGE_BUFFER         
            gNormal = vec4(frontFaceNormal(vNormal), vId);         
            gFlagsAndCuts = vec4(0.0);
            #if NUM_CLIPPING_PLANES > 0         
                gFlagsAndCuts.r = 1.0 - clamp(planeDistance / length(vClipPosition) * 200.0, 0.0, 1.0); // cut edge intensity factor
            #endif
            #ifdef RENDER_EDGES
                #ifdef PRECISE_EDGES
                    // dont render precise normal based edges on curved geometry
                    bool isFlatSurface = abs(dot(vNormal, flatNormal)) > 0.9999 && !any(greaterThan(abs(dFdx(vNormal)), vec3(0.001))) && !any(greaterThan(abs(dFdy(vNormal)), vec3(0.001)));
                    gFlagsAndCuts.g = isFlatSurface ?
                        1.0 : // render edges using normals (more sensitive to normals angle) and depth
                        0.5; // render edges using depth only, to avoid artifacts on curved surfaces
                #else
                    gFlagsAndCuts.g = 0.75; // render edges using normals and depth    
                #endif
            #endif
        #endif
    }
`;

// language=GLSL
const meshVertexShader = `
    #if defined(VERTEX_INTERPOLATION)
        uniform float ambient;
        uniform float intensity;
        varying float vAttenuation;
    #else
        varying vec3 vPosition;
    #endif
        
    #if defined(OUT_NORMAL_EDGE_BUFFER)
        uniform float uId;
        varying float vId;
        attribute float id;
    #endif

    varying vec3 vNormal;
    flat varying vec3 flatNormal;

    #include <common>
    #include <uv_pars_vertex>
    #include <color_pars_vertex>
    #include <clipping_planes_pars_vertex>

    void main() {
        #include <uv_vertex>
        #include <color_vertex>
        #include <beginnormal_vertex>
        #include <defaultnormal_vertex>
        #include <begin_vertex>
        #include <project_vertex>
        #include <worldpos_vertex>
        
        #if defined(VERTEX_INTERPOLATION)
            vec3 normal = normalize(transformedNormal);
            vec3 viewDir = vec3(viewMatrix[1][3], viewMatrix[2][3], viewMatrix[3][3]);
            vAttenuation = abs(dot(viewDir, normal));
            vAttenuation = clamp(vAttenuation * intensity + ambient, 0.0, 2.0);
        #else
            vPosition = mvPosition.xyz;
        #endif
            
        #if defined(OUT_NORMAL_EDGE_BUFFER)
            vId = (id + uId) / ${0x7FFFFFFF}.0;
        #endif

        vNormal = transformedNormal;
        flatNormal = transformedNormal;
            
        #include <clipping_planes_vertex>
    }
`;

export interface Web3DMeshMaterialParameters extends ShaderMaterialParameters {
    color: Color | string | number;
    map?: Texture;
    phong?: PhongSetting;
    physical?: PhysicalSetting; // requires globalUniforms.envMap
    isDoubleGeometryPart?: boolean;
    id?: number; // for edges rendering between geometry with different id (alternative for id mesh attribute)
    outputNormalEdgeBuffer?: boolean;
    renderEdges?: boolean;
    preciseEdges?: boolean;
    vertexInterpolation?: boolean;
    instancing?: boolean;
}

export interface PhongSetting {
    ambient: number;
    intensity: number;
    specular: number;
    specularHardness: number;
}
export interface PhysicalSetting {
    metalness: number;
    roughness: number;
}

export const defaultPhong = {ambient: 0.55, intensity: 0.5, specular: 0.7, specularHardness: 8} as PhongSetting;
export const darkModePhong = {ambient: 1.1, intensity: -0.6, specular: 0.6, specularHardness: 10} as PhongSetting;

export class Web3DMeshMaterial extends Web3DMaterial {
    readonly isWVMeshMaterial: boolean;

    private readonly isDoubleGeometryPart: boolean;
    private readonly originalSide: Side;


    // Called by WebGLRenderer.setProgram to refresh material before rendering
    get isPointsMaterial(): boolean {
        this.updateTransparency();
        return false;
    }

    get envMap(): Texture {
        return this.uniforms.envMap.value;
    }

    enableForceDoubleSide(): void {
        if (this.side !== DoubleSide && !this.isDoubleGeometryPart) {
            this.side = DoubleSide;
            this.needsUpdate = true;
        }
    }

    disableForceDoubleSide(): void {
        if (!this.isDoubleGeometryPart) {
            this.side = this.originalSide;
            this.needsUpdate = true;
        }
    }

    set outputNormalEdgeBuffer(value: boolean) {
        this.defines.OUT_NORMAL_EDGE_BUFFER = value;
        this.needsUpdate = true;
    }

    get outputNormalEdgeBuffer(): boolean {
        return !!this.defines.OUT_NORMAL_EDGE_BUFFER;
    }

    set renderEdges(value: boolean) {
        this.defines.RENDER_EDGES = value;
        this.needsUpdate = true;
    }

    get renderEdges(): boolean {
        return !!this.defines.RENDER_EDGES;
    }

    set preciseEdges(value: boolean) {
        this.defines.PRECISE_EDGES = value;
        this.needsUpdate = true;
    }

    get preciseEdges(): boolean {
        return !!this.defines.PRECISE_EDGES;
    }

    get vertexInterpolation(): boolean {
        return !!this.defines.VERTEX_INTERPOLATION;
    }

    set vertexInterpolation(value: boolean) {
        this.defines.VERTEX_INTERPOLATION = value;
        this.dithering = !value;
        this.needsUpdate = true;
    }

    constructor(parameters: Web3DMeshMaterialParameters, globalUniforms?: GlobalMaterialUniforms) {
        const uniforms = Object.assign(UniformsUtils.clone(ShaderLib.basic.uniforms), {
            diffuse: {value: new Color(parameters.color)},
            opacity: {value: parameters.opacity ?? 1},
            transparent: {value: Number(parameters.transparent ?? false)},
            map: {type: 't', value: parameters.map},
            ambient: {value: parameters.phong.ambient},
            intensity: {value: parameters.phong.intensity},
            specular: {value: parameters.phong.specular},
            specularHardness: {value: parameters.phong.specularHardness},
            metalness: {value: parameters.physical ? parameters.physical.metalness : 0},
            roughness: {value: parameters.physical ? parameters.physical.roughness : 0},
            uId: {value: parameters.id}
        }, globalUniforms || {peelingDepthTexture: {value: null}});

        const params = Object.assign({
            uniforms: uniforms,
            defines: {
                USE_MAP: !!parameters.map,
                USE_UV: !!parameters.map,
                MAP_UV: "uv",
                OUT_NORMAL_EDGE_BUFFER: !!parameters.outputNormalEdgeBuffer,
                RENDER_EDGES: parameters.renderEdges === undefined || parameters.renderEdges,
                PRECISE_EDGES: !!parameters.preciseEdges,
                VERTEX_INTERPOLATION: !!parameters.vertexInterpolation,
                USE_INSTANCING: !!parameters.instancing
            },
            vertexShader: meshVertexShader,
            fragmentShader: meshFragmentShader
        }, parameters);
        const isDoubleGeometryPart = params.isDoubleGeometryPart;
        delete params.isDoubleGeometryPart;
        delete params.color;
        delete params.map;
        delete params.phong;
        delete params.physical;
        delete params.outputNormalEdgeBuffer;
        delete params.renderEdges;
        delete params.vertexInterpolation;
        delete params.instancing;
        delete params.id;
        delete params.preciseEdges;

        super(params, globalUniforms);
        this.isWVMeshMaterial = true;

        this.dithering = !parameters.vertexInterpolation;
        this.originalSide = params.side;
        this.isDoubleGeometryPart = isDoubleGeometryPart;
    }

    static createSettingsAwareMaterial(settingsDispatcher: SettingsDispatcher<Settings>, renderingManager: RenderingManager, p: Web3DMeshMaterialParameters): Web3DMeshMaterial {
        if (!p.phong) p.phong = settingsDispatcher.settings.darkModeMaterials ? darkModePhong : defaultPhong;
        p.outputNormalEdgeBuffer = settingsDispatcher.settings.renderEdges;
        p.vertexInterpolation = settingsDispatcher.settings.vertexInterpolationMaterials;
        p.clippingPlanes = renderingManager.clippingPlanes;
        const material = new Web3DMeshMaterial(p, renderingManager.uniforms);
        settingsDispatcher.subscribe("darkModeMaterials", () => {
            const phong = settingsDispatcher.settings.darkModeMaterials ? darkModePhong : defaultPhong;
            material.uniforms.ambient.value = phong.ambient;
            material.uniforms.intensity.value = phong.intensity;
            material.uniforms.specular.value = phong.specular;
            material.uniforms.specularHardness.value = phong.specularHardness;
        });

        settingsDispatcher.subscribe("renderEdges", () => material.outputNormalEdgeBuffer = settingsDispatcher.settings.renderEdges);
        settingsDispatcher.subscribe("vertexInterpolationMaterials", () => material.vertexInterpolation = settingsDispatcher.settings.vertexInterpolationMaterials);
        settingsDispatcher.subscribe("environmentMapUrl", () => material.uniforms.envMap = renderingManager.uniforms.envMap);
        return material;
    }
}
