import {Model, SelectableModel} from "./Model.js";
import {IIntersection} from "./Picker/IIntersection.js";
import {Caster} from "./Picker/Caster.js";
import {Api} from "./Api.js";
import {ClipBox} from "./ClipBox.js";
import {ClipBoxTool} from "./Tools/ClipBoxTool.js";
import {ClipPlane} from "./ClipPlane.js";

type CLippingObject = ClipPlane | ClipBox;

export class ClipPlaneModel extends Model implements SelectableModel {
    static get ModelId(): string { return "clipPlane"; }
    readonly isSelectable = true;

    constructor(
        private _api: Api
    ) {
        super(ClipPlaneModel.ModelId);
    }

    areaPick(caster: Caster): Promise<IIntersection> {
        const clippingObjects: CLippingObject[] = [];
        this.root.children.forEach(cpObj => {
            if (cpObj instanceof ClipPlane) {
                if (caster.frustum.intersectsObject((cpObj as ClipPlane).handle))
                    clippingObjects.push(cpObj as ClipPlane);
            } else if (cpObj instanceof ClipBox) {
                if (caster.frustum.intersectsObject((cpObj as ClipBox).boxMesh)) {
                    clippingObjects.push(cpObj as ClipBox);
                }
            }
        });

        if (clippingObjects.length === 0) return;

        return Promise.resolve({
            model: this,
            object: clippingObjects[0],
            id: clippingObjects[0].id,
            childrenIds: clippingObjects.map(cpObj => cpObj.id),
            caster: caster
        });
    }

    async pick(caster: Caster): Promise<IIntersection> {
        // handles have priority over box
        for (const cp of this.root.children as ClipPlane[]) {
            if (!(cp instanceof ClipPlane && cp.controlsVisible))
                continue;
            const intersection = caster.intersectObject(cp.handle)[0];
            if (intersection) {
                const result: IIntersection = { object: cp, id: cp.id, caster: caster, model: this, pickPriority: 1 };
                return Promise.resolve(Object.assign(intersection, result));
            }
        }

        for (const cp of this.root.children as ClipBox[]) {
            if (cp instanceof ClipBox) {
                const intersection = caster.intersectObject(cp, false)[0];
                if (intersection) {
                    const result: IIntersection = { object: cp, id: cp.id, caster: caster, model: this, pickPriority: 1 };
                    return Promise.resolve(Object.assign(intersection, result));
                }
            }
        }
    }

    _clearSelection(): void {
        this.root.children.forEach(obj => {
            if (obj instanceof ClipPlane)
                obj.deselect();
            if (obj instanceof ClipBox) {
                const tool = this._api.toolManager.tools.clipBox as ClipBoxTool;
                tool.stopEditing();
                obj.deselect();
            }
        });
    }

    _setSelection(ids: number[]): void {
        this._clearSelection();

        ids.forEach(id => {
            const obj = this.root.getObjectById(id);
            if (obj instanceof ClipPlane)
                (this.root.getObjectById(id) as ClipPlane).select();
            if (obj instanceof ClipBox) {
                obj.select();
                const tool = this._api.toolManager.tools.clipBox as ClipBoxTool;
                tool.startEditing();
            }
        });
    }

    updateBoundingBox(): void {
        for (const c of this.root.children) {
            if (c instanceof ClipBox) {
                this.boundingBox.value.copy(c.getWorldBoundingBox());
                this.boundingBox.next(this.boundingBox.value);
                break;
            }
        }
    }
}
