import {Background} from "./Background.js";
import {EdgesAndSsaoRenderPass} from "./EdgesAndSsaoRenderPass.js";
import {SelectionEffectPass} from "./SelectionEffectPass.js";
import {Api} from "../Api.js";
import {
    DepthTexture,
    RenderTarget, Texture,
} from "three";
import {Pass} from "three/examples/jsm/postprocessing/Pass.js";
import {RenderingManager} from "./RenderingManager.js";

export abstract class PassComposer {
    protected renderTarget: RenderTarget;
    protected abstract background: Background;
    protected abstract edgesAndSsaoRenderPass: EdgesAndSsaoRenderPass;
    protected abstract selectionEffectPass: SelectionEffectPass;
    protected fxaaEnabled: boolean;

    protected beforeMainPasses: Pass[] = [];
    protected beforeSelectionPasses: Pass[] = [];
    protected afterSelectionPasses: Pass[] = [];
    protected beforeAntialiasingPasses: Pass[] = [];
    protected afterAntialiasingPasses: Pass[] = [];

    protected constructor(protected api: Api, protected renderingManager: RenderingManager) {
        this.api.settingsDispatcher.subscribe("antialias", () => this.updateFxaa());
        this.api.settingsDispatcher.subscribe("fxaa", () => this.updateFxaa());
    }

    abstract render(renderMain?: boolean): void;
    protected abstract updateFxaa(): void;

    get colorTexture(): Texture {
        return this.renderTarget.texture;
    }
    get normalTexture(): Texture {
        return this.edgesAndSsaoRenderPass.normalTexture; }
    get depthTexture(): DepthTexture {
        return this.renderTarget.depthTexture;
    }
    isCubeBackground(): boolean {
        return this.background.isCube();
    }

    addPassBeforeMain(pass: Pass): void {
        this.beforeMainPasses.push(pass);
    }
    addPassBeforeSelection(pass: Pass): void {
        this.beforeSelectionPasses.push(pass);
    }
    addPassAfterSelection(pass: Pass): void {
        this.afterSelectionPasses.push(pass);
    }
    addPassBeforeAntialiasing(pass: Pass): void {
        this.beforeAntialiasingPasses.push(pass);
    }
    addPassAfterAntialiasing(pass: Pass): void {
        this.afterAntialiasingPasses.push(pass);
    }

    setSize(width: number, height: number): void {
        this.renderTarget.setSize(width, height);
        this.selectionEffectPass.setSize(width, height);
        this.beforeMainPasses.forEach(p => p.setSize(width, height));
        this.beforeSelectionPasses.forEach(p => p.setSize(width, height));
        this.afterSelectionPasses.forEach(p => p.setSize(width, height));
        this.beforeAntialiasingPasses.forEach(p => p.setSize(width, height));
        this.afterAntialiasingPasses.forEach(p => p.setSize(width, height));
    }

    dispose(): void {
        this.background.dispose();
        this.edgesAndSsaoRenderPass.dispose();
        this.selectionEffectPass.dispose();
        this.renderTarget.dispose();
        this.beforeMainPasses.forEach(p => p.dispose());
        this.beforeSelectionPasses.forEach(p => p.dispose());
        this.afterSelectionPasses.forEach(p => p.dispose());
        this.beforeAntialiasingPasses.forEach(p => p.dispose());
        this.afterAntialiasingPasses.forEach(p => p.dispose());
    }
}
