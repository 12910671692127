import {Background} from "../Background.js";
import {RenderingManager} from "../RenderingManager.js";
import {BackgroundGradient, Settings} from "../../common.js";
import {Web3DCamera} from "../Web3DCamera.js";
import {SettingsDispatcher} from "../../SettingsDispatcher.js";
import {
    FrontSide, Matrix3,
    Mesh,
    PlaneGeometry,
    Texture,
    WebGPUCoordinateSystem
} from "three";
import {MeshBasicNodeMaterial, wgslFn, uniform, attribute, uv, texture, mat3, ShaderNodeObject, UniformNode} from "../../Three.WebGPU.js";
import {Api} from "../../Api.js";

export class WebGPUBackground extends Background {

    private uvTransformUniform: ShaderNodeObject<UniformNode<any>>;
    private textureUniform: ShaderNodeObject<UniformNode<any>>;

    renderColor(): void {
        // The single-colour case is captured by the update() method and turned into a gradient
    }

    renderTexture(): void {
        const background = this.background as Texture;

        if (this.planeMesh === undefined) {
            const planeGeometry = new PlaneGeometry(2, 2);
            this.planeMesh = new Mesh(planeGeometry, this.createPlaneMaterial());
            planeGeometry.deleteAttribute('normal');

            this.backgroundScene.children.length = 0;
            this.backgroundScene.add(this.planeMesh);
        }

        if (this.boxMesh) {
            this.backgroundScene.remove(this.boxMesh);
            this.boxMesh = undefined;
        }

        if (background.matrixAutoUpdate === true) {
            // @ts-ignore
            this.background.updateMatrix();
        }

        // @ts-ignore
        this.uvTransformUniform.value.copy(this.background.matrix);

        if (this.currentBackground !== this.background ||
            this.currentBackgroundVersion !== background.version) {

            this.textureUniform.value = this.background;

            // @ts-ignore
            this.planeMesh.material.needsUpdate = true;

            this.currentBackground = this.background;
            this.currentBackgroundVersion = background.version;
        }

        this.syncCamera();
        this.renderingManager.renderer.render(this.backgroundScene, this.backgroundCamera);
    }

    renderCubemap(): void {
        throw new Error("Not implemented");
    }

    override update(): void {
        // Capture single-colour background and turn it into a trivial gradient.
        // TODO: Render single-colour backgrounds by setting clear colour and clearing the render target.
        // Renderer.setClearColour() is not supported by Three.js for WebGPU yet.
        super.update();
        const color = this.toColor(this.settingsDispatcher.settings.background);
        if (color) this.background = this.toGradient({ topColor: color, bottomColor: color } as BackgroundGradient);
    }

    private createPlaneMaterial(): MeshBasicNodeMaterial {
        const planeMaterial = new MeshBasicNodeMaterial();

        this.uvTransformUniform = uniform(mat3(new Matrix3())).label('uvTransform');
        this.textureUniform = texture(undefined).label('t2D');

        // language=wgsl
        const colorNode = wgslFn(`
            fn getColor(uv: vec2<f32>) -> vec4<f32> {
                var U = object;
                var transformedUV: vec2<f32> = (U.uvTransform * vec3(uv, 1.0)).xy;
                return textureSample(t2D, t2D_sampler, transformedUV);
            }
        `, [this.uvTransformUniform, this.textureUniform]);

        const params = {
            position: attribute('position', 'vec3'),
            uv: uv()
        };

        planeMaterial.vertexNode = wgslFn(`
            fn getPosition(position: vec3<f32>) -> vec4<f32> {
                return vec4(position.xy, 1.0, 1.0);
            }
            // Background vertex shader
        `)(params);

        planeMaterial.colorNode = colorNode(params);

        planeMaterial.side = FrontSide;
        planeMaterial.depthTest = false;
        planeMaterial.depthWrite = false;
        planeMaterial.fog = false;
        planeMaterial.dithering = true;

        return planeMaterial;
    }

}
