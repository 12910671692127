import {
    Scene, WebGLRenderer, WebGLRenderTarget,
    DepthTexture, RGBAFormat, UnsignedByteType, ShaderMaterial,
    FloatType, Material, ShaderChunk
} from "three";
import { RenderingManager } from "./RenderingManager.js";
import { EffectPass } from "./EffectPass.js";
import { DepthPeelingMaterial } from "./DepthPeelingMaterial.js";
import {Web3DCamera} from "./Web3DCamera.js";
import {EdgesAndSsaoRenderPass} from "./EdgesAndSsaoRenderPass.js";
import {Pass} from "three/examples/jsm/postprocessing/Pass.js";
import {SettingsDispatcher} from "../SettingsDispatcher.js";
import {Settings} from "../common.js";

export class DepthPeelingRenderPass extends Pass {
    private peelRenderTargets: WebGLRenderTarget[];
    private combinePeelsPass: EffectPass;

    private get depthPeelingEnabled(): boolean {
        return this.settingsDispatcher.settings.orderIndependentTransparency &&
            !this.renderingManager.xr.isStarted;
    }

    get peelsCount(): number {
        return this.peelRenderTargets.length;
    }

    set peelsCount(peelsCount: number) {
        this.peelRenderTargets = [];
        for (let i = 0; i < peelsCount; i++) {
            const renderTarget = this.createRenderTarget();
            renderTarget.depthTexture = new DepthTexture(undefined, undefined, FloatType);
            this.peelRenderTargets.push(renderTarget);
        }
    }

    constructor(private renderingManager: RenderingManager, private settingsDispatcher: SettingsDispatcher<Settings>, public scene: Scene, public camera: Web3DCamera, private edgesAndSsaoPass: EdgesAndSsaoRenderPass) {
        super();
        // language=GLSL
        (ShaderChunk as any).depth_peeling_pars_fragment = `
            #define depthPrecisionFix 0.000001
            
            uniform int depthPeelingEnabled;
            uniform int transparent;
            uniform highp sampler2D peelingDepthTexture;
            uniform vec2 viewSize;
            
            void depthPeelingFragment() {
                if (depthPeelingEnabled != 0 && transparent != 0) {
                    float depth = gl_FragCoord.z;
                    float prevDepth = texture2D(peelingDepthTexture, gl_FragCoord.xy / viewSize).r;
                    if (prevDepth + depthPrecisionFix >= depth) discard;
                }   
            }
        `;
        (ShaderChunk as any).depth_peeling_fragment = `depthPeelingFragment();`;

        settingsDispatcher.subscribe("orderIndependentTransparency", () => renderingManager.redraw());
        this.peelsCount = settingsDispatcher.settings.transparencyPeelsCount;
        settingsDispatcher.subscribe("transparencyPeelsCount", () => {
            this.peelsCount = settingsDispatcher.settings.transparencyPeelsCount;
            renderingManager.redraw();
        });
        renderingManager.uniforms.depthPeelingEnabled.value = Number(this.depthPeelingEnabled);
        this.combinePeelsPass = new EffectPass(this.createCombinePeelsMaterial());
    }

    private createRenderTarget(): WebGLRenderTarget {
        return new WebGLRenderTarget(
            this.renderingManager.width,
            this.renderingManager.height,
            {
                format: RGBAFormat,
                type: UnsignedByteType,
                depthBuffer: true,
                stencilBuffer: false
            }
        ) as WebGLRenderTarget;
    }

    private createCombinePeelsMaterial(): ShaderMaterial {
        return new ShaderMaterial({
            uniforms: {
                inputBuffer: { value: null },
                depthBuffer: { value: null },
            },
            // language=GLSL
            vertexShader: `
                varying vec2 vUv;
                
                void main() {
                    vUv = uv;
                    gl_Position = vec4(position.xy, 0.0, 1.0);
                }
            `,
            // language=GLSL
            fragmentShader: `
                varying vec2 vUv;
                uniform sampler2D inputBuffer;
                uniform highp sampler2D depthBuffer;

                void main() {
                    gl_FragDepth = texture2D(depthBuffer, vUv).r;
                    gl_FragColor = texture2D(inputBuffer, vUv);
                }
            `,
            transparent: true,
            depthTest: true,
            depthWrite: true
        });
    }

    private renderWithDepthPeeling(renderer: WebGLRenderer, renderTarget: WebGLRenderTarget): void {
        this.renderingManager.uniforms.globalTransparent = false;
        renderer.setClearColor(0x000000, 0);

        // Render peel layers into buffers
        for (let i = 0; i < this.peelsCount; i++) {
            this.renderingManager.uniforms.depthPeelingEnabled.value = Number(i > 0);
            this.renderingManager.uniforms.peelingDepthTexture.value = i === 0 ? null : this.peelRenderTargets[i - 1].depthTexture;
            renderer.setRenderTarget(this.peelRenderTargets[i]);
            renderer.clear();
            this.edgesAndSsaoPass.render(renderer, this.peelRenderTargets[i], false,
                (m: Material) => (m as DepthPeelingMaterial).isDepthPeelingMaterial && ((m as DepthPeelingMaterial).origTransparent || this.settingsDispatcher.settings.globalOpacity < 1.0) // only transparent meshes
            );
        }

        this.renderingManager.uniforms.globalTransparent = undefined;

        // Render the rest with traditional transparency
        this.renderingManager.uniforms.depthPeelingEnabled.value = Number(true);
        this.renderingManager.uniforms.peelingDepthTexture.value = this.peelRenderTargets[this.peelsCount - 1].depthTexture;
        this.renderingManager.uniforms.globalTransparent = this.settingsDispatcher.settings.globalOpacity < 1.0 ? true : undefined;
        this.edgesAndSsaoPass.render(renderer, renderTarget, true,
            (m: Material) => m.depthTest // non-depth tested materials will be rendered last
        );
        this.renderingManager.uniforms.globalTransparent = undefined;
        this.renderingManager.uniforms.depthPeelingEnabled.value = Number(false);
        this.renderingManager.uniforms.peelingDepthTexture.value = null;

        // Blit depth peels above
        for (let i = this.peelsCount - 1; i >= 0; i--) {
            this.combinePeelsPass.getFullscreenMaterial().uniforms.inputBuffer.value = this.peelRenderTargets[i].texture;
            this.combinePeelsPass.getFullscreenMaterial().uniforms.depthBuffer.value = this.peelRenderTargets[i].depthTexture;
            this.combinePeelsPass.render(renderer, renderTarget);
        }

        // render non-depth tested materials above everything
        this.renderingManager.uniforms.globalTransparent = this.settingsDispatcher.settings.globalOpacity < 1.0 ? true : undefined;
        this.edgesAndSsaoPass.render(renderer, renderTarget, false,
            (m: Material) => !m.depthTest
        );
        this.renderingManager.uniforms.globalTransparent = undefined;
    }

    override setSize(width: number, height: number): void {
        for (const t of this.peelRenderTargets)
            t.setSize(width, height);
        this.edgesAndSsaoPass.setSize(width, height);
    }

    override render(renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget): void {
        this.renderingManager.uniforms.globalOpacity.value = this.settingsDispatcher.settings.globalOpacity;
        if (this.depthPeelingEnabled) {
            this.renderWithDepthPeeling(renderer, writeBuffer);
        }
        else {
            this.renderingManager.uniforms.globalTransparent = this.settingsDispatcher.settings.globalOpacity < 1.0 ? true : undefined;
            this.edgesAndSsaoPass.render(renderer, writeBuffer, true, () => true);
            this.renderingManager.uniforms.globalTransparent = undefined;
        }
    }
}
