import {
    Color,
    DoubleSide,
    MeshBasicMaterial, RenderTarget,
    ShaderMaterial,
    ShaderMaterialParameters,
    Uniform,
    WebGLRenderer, WebGLRenderTarget
} from "three";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass.js";
import {EffectPass} from "./EffectPass.js";
import {OutlineEffectMaterial} from "./OutlineEffectMaterial.js";
import {SelectionEffectPass} from "./SelectionEffectPass.js";
import {RenderingManager} from "./RenderingManager.js";
import {Api} from "../Api.js";

// language=GLSL
const fragment = `
varying vec2 vUv;    
    
uniform sampler2D edgeTexture;
uniform sampler2D maskTexture;

uniform vec3 color;
uniform float edgeStrength;
uniform float fillStrength;

void main() {
	float edge = texture2D(edgeTexture, vUv).r;
	float mask = texture2D(maskTexture, vUv).r;

	edge = clamp(edge * edgeStrength * mask, 0.0, 1.0);
	mask = (1.0 - mask) * fillStrength;
    gl_FragColor = vec4(color, edge + mask);
}
`;

export class WebGLSelectionEffectPass extends SelectionEffectPass {
    maskMaterial: MeshBasicMaterial;
    private maskPass: RenderPass;

    constructor(api: Api, renderingManager: RenderingManager) {
        const material = new ShaderMaterial({
            uniforms: {},
            // language=GLSL
            vertexShader: `
                varying vec2 vUv;

                void main() {
                    vUv = uv;
                    gl_Position = vec4(position.xy, 0.0, 1.0);
                }
            `,
            fragmentShader: fragment,
            transparent: true,
            depthTest: false,
            depthWrite: false
        } as ShaderMaterialParameters);
        super(material, renderingManager);
        material.transparent = true;

        material.uniforms.maskTexture = new Uniform(null);
        material.uniforms.edgeTexture = new Uniform(null);
        material.uniforms.edgeStrength = new Uniform(this.edgeStrength);
        material.uniforms.fillStrength = new Uniform(this.fillStrength);
        material.uniforms.color = new Uniform(api.settingsDispatcher.settings.selectionColor);

        this.getFullscreenMaterial().uniforms.maskTexture.value = this.renderTargetMask.texture;
        this.getFullscreenMaterial().uniforms.edgeTexture.value = this.renderTargetOutline.texture;

        this.maskPass = new RenderPass(this.selectionScene, api.camera);
        this.maskPass.clear = false;
        this.maskMaterial = new MeshBasicMaterial({color: this.maskColor, side: DoubleSide});

        this.outlinePass = new EffectPass(new OutlineEffectMaterial());
        this.outlinePass.getFullscreenMaterial().uniforms.maskTexture.value = this.renderTargetMask.texture;

    }

    override render(renderer: WebGLRenderer, writeBuffer: RenderTarget): void {
        if (this.selectionScene.children.length > 0 || this.shouldClear) {
            renderer.setRenderTarget(this.renderTargetMask as WebGLRenderTarget);
            renderer.setClearColor(0xffffff, 1);
            renderer.clear();
            this.maskPass.render(renderer, undefined, this.renderTargetMask as WebGLRenderTarget, undefined, undefined);
            this.outlinePass.render(renderer, this.renderTargetOutline);
            super.render(renderer, writeBuffer);
            this.shouldClear = false;
        }
    }

    override setSize(width: number, height: number): void {
        super.setSize(width, height);

        const w = width / window.devicePixelRatio;
        const h = height / window.devicePixelRatio;
        this.maskPass.setSize(w, h);
    }
}
