import {
    Color,
    CubeTexture,
    Euler,
    Scene,
    Texture,
    WebGLCubeRenderTarget,
    Mesh,
    PerspectiveCamera,
    RepeatWrapping,
    MathUtils,
    WebGLRenderTarget, VideoTexture,
} from "three";
import {RenderingManager} from "./RenderingManager.js";
import {BackgroundGradient, BackgroundSetting, Settings} from "../common.js";
import {Web3DCamera} from "./Web3DCamera.js";
import {EquirectangularToCubeGenerator} from "./EquirectangularToCubeGenerator.js";
import {SettingsDispatcher} from "../SettingsDispatcher.js";
import {Api} from "../Api.js";

export abstract class Background {
    protected settingsDispatcher: SettingsDispatcher<Settings>;
    protected camera: Web3DCamera;

    protected background: Color | Texture | CubeTexture | WebGLCubeRenderTarget;
    protected currentBackground: Color | Texture | CubeTexture | WebGLCubeRenderTarget;

    protected boxMesh: Mesh;
    protected planeMesh: Mesh;
    protected currentBackgroundVersion = 0;

    protected backgroundEuler = new Euler();
    protected backgroundScene = new Scene();
    protected backgroundCamera: Web3DCamera;

    protected abstract renderColor(): void;
    protected abstract renderTexture(): void;
    protected abstract renderCubemap(): void;

    constructor(api: Api, protected renderingManager: RenderingManager) {
        this.settingsDispatcher = api.settingsDispatcher;
        this.camera = api.camera;
        this.backgroundCamera = new Web3DCamera(api);
        this.update();

        this.settingsDispatcher.subscribe("background", () => {
            this.update();
            this.renderingManager.redraw();
        });

        this.settingsDispatcher.subscribe("environmentMapUrl", () => {
            this.renderingManager.redraw();
        });

        this.settingsDispatcher.subscribe("backgroundRotation", () => {
            this.renderingManager.redraw();
        });
    }

    isCube(): boolean {
        return !!this.boxMesh;
    }

    render(): void {
        // Ignore background in AR
        if (this.renderingManager.xr.isStartedAR) return;

        this.renderingManager.renderer.clearDepth();

        if (!this.background && !this.renderingManager.uniforms.envMap.value)
            return;

        if (this.background && (this.background instanceof CubeTexture || this.background instanceof WebGLCubeRenderTarget) ||
            this.settingsDispatcher.settings.background === "environment" && this.renderingManager.uniforms.envMap.value)
            this.renderCubemap();
        else if (this.background && this.background instanceof Texture)
            this.renderTexture();
        else if (this.background && this.background instanceof Color)
            this.renderColor();
    }

    protected syncCamera(): void {
        this.backgroundEuler.x = -Math.PI / 2;
        this.backgroundEuler.z = this.settingsDispatcher.settings.backgroundRotation * Math.PI / 180;
        this.backgroundCamera.quaternion.setFromEuler(this.backgroundEuler);
        this.backgroundCamera.quaternion.multiply(this.camera.quaternion);
        if (
            this.backgroundCamera.aspect !== this.camera.aspect ||
            this.backgroundCamera.fov !== this.camera.fov
        ) {
            this.backgroundCamera.aspect = this.camera.aspect;
            this.backgroundCamera.fov = this.camera.fov;
            this.backgroundCamera.updateProjectionMatrix();
        }
    }

    protected update(): void {
        if (this.background && this.background instanceof Texture ||
            this.background instanceof CubeTexture ||
            this.background instanceof WebGLCubeRenderTarget ||
            this.background instanceof WebGLRenderTarget) {
            this.background.dispose();
        }
        this.background =
            this.toColor(this.settingsDispatcher.settings.background) ||
            this.toGradient(this.settingsDispatcher.settings.background) ||
            this.toSkybox(this.settingsDispatcher.settings.background) ||
            this.toTexture(this.settingsDispatcher.settings.background) ||
            this.toVideoTexture(this.settingsDispatcher.settings.background);
    }

    protected toGradient(background: BackgroundSetting): Texture {
        const gradient = background as BackgroundGradient;
        if (gradient.topColor && gradient.bottomColor) {
            const canvas = document.createElement("canvas");
            canvas.width = 1;
            canvas.height = 2;
            const context = canvas.getContext("2d");
            context.fillStyle = "#" + gradient.topColor.getHexString();
            context.fillRect(0, 0, 1, 1);
            context.fillStyle = "#" + gradient.bottomColor.getHexString();
            context.fillRect(0, 1, 1, 1);
            const texture = this.toTexture(canvas);

            // crop half pixel on top and bottom for better vertical gradients
            texture.repeat.y = (canvas.height - 1) / canvas.height;
            texture.offset.y = 0.5 / canvas.height;
            return texture;
        }

    }

    protected toColor(background: BackgroundSetting): Color {
        const color = background as Color;
        return color.r !== undefined && color.g !== undefined && color.b !== undefined ? color : undefined;
    }

    protected toTexture(background: BackgroundSetting): Texture {
        if (background instanceof HTMLImageElement || background instanceof HTMLCanvasElement || background instanceof ImageBitmap) {
            const texture = new Texture(background as any);
            texture.needsUpdate = true;
            return texture;
        }
    }

    protected toVideoTexture(background: BackgroundSetting): Texture {
        if (background instanceof HTMLVideoElement) {
            const texture = new VideoTexture(background as any);
            texture.needsUpdate = true;
            return texture;
        }
    }

    protected toSkybox(background: BackgroundSetting): CubeTexture | WebGLCubeRenderTarget {
        if (background instanceof HTMLImageElement || background instanceof HTMLCanvasElement || background instanceof ImageBitmap) {
            if (background.height * 4 === background.width * 3) {
                const cubeTexture = new CubeTexture(this.getImagesFromSkyboxAtlas(background));
                cubeTexture.needsUpdate = true;
                return cubeTexture;
            }
            else if (background.height * 2 === background.width) {
                const textureSrc = new Texture(background as any);
                textureSrc.wrapS = RepeatWrapping;
                textureSrc.needsUpdate = true;
                const equiToCube = new EquirectangularToCubeGenerator(textureSrc, { resolution: MathUtils.ceilPowerOfTwo(background.height * 0.6) });
                const cubeTexture = equiToCube.update(this.renderingManager.renderer);
                textureSrc.dispose();
                return cubeTexture;
            }
        }
        else if (background instanceof Array) {
            const cubeTexture = new CubeTexture(background);
            cubeTexture.needsUpdate = true;
            return cubeTexture;
        }
    }

    private getImagesFromSkyboxAtlas(srcImg: HTMLImageElement | HTMLCanvasElement | ImageBitmap): HTMLCanvasElement[] {
        const imgs: HTMLCanvasElement[] = [];
        const imageObj = srcImg;
        const size = imageObj.height / 3;
        for (let i = 0; i < 6; i++) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = size;
            canvas.width = size;
            if (i === 0) context.drawImage(imageObj, size * 2, size, size, size, 0, 0, size, size);
            if (i === 1) context.drawImage(imageObj, 0, size * i, size, size, 0, 0, size, size);
            if (i === 2) context.drawImage(imageObj, size, 0, size, size, 0, 0, size, size);
            if (i === 3) context.drawImage(imageObj, size, size * 2, size, size, 0, 0, size, size);
            if (i === 4) context.drawImage(imageObj, size, size, size, size, 0, 0, size, size);
            if (i === 5) context.drawImage(imageObj, size * 3, size, size, size, 0, 0, size, size);
            imgs.push(canvas);
        }
        return imgs;
    }

    dispose(): void {
        if (this.planeMesh) this.planeMesh.geometry.dispose();
        if (this.boxMesh) this.boxMesh.geometry.dispose();
    }
}
